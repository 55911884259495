import React from 'react'
import "./contact.min.css"

export default function Contact() {
  return (
    <div className="contact" id="contact">

    </div>
  )
}
